import { IUser } from '@/interfaces/user.interface';
import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import categories from '@/api/categories';
import { Categories, ICategory, ICategoryResponse } from '@/interfaces/categories.interface';


interface CategoriesState {
    listCategories: () => Promise<ICategoryResponse[]>;
    categories: ICategoryResponse[] | [];

}

const useCategoriesStore = create<CategoriesState>()(
    persist(
        (set) => ({
            categories: [],
            listCategories: async () => {
                // list all categories
                const response = await categories.listCategories();

                console.log('categories', response);

                set((state) => ({
                    ...state,
                    categories: response.data
                }));
                return response.data;
            },
        }),
        {
            name: 'categories',
        }
    )
);

export default useCategoriesStore;