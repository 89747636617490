import { AxiosResponse } from 'axios';
import client from './client';

import { setCookie } from 'cookies-next';
import { Categories, ICategory, ICategoryResponse } from '@/interfaces/categories.interface';
import { ISubCategories } from '@/interfaces/subcategories.interface';

const ENDPOINT = "categories";


const listCategories = (): Promise<AxiosResponse<ICategoryResponse[]>> => {
    const url = `${client.BASE_URL}/api/client/${ENDPOINT}`;

    console.log('url', url);

    return new Promise((resolve, reject) => {
        client.get(url)
            .then(response => {
                resolve(response);
            })
            .catch(reject);
    });
};

const getSubcategoriesByCategoryId = (categoryId: string): Promise<AxiosResponse<ISubCategories[]>> => {
    const url = `${client.BASE_URL}/api/client/${ENDPOINT}/list-subcategories?categoryId=${categoryId}`;

    console.log('url', url);

    return new Promise((resolve, reject) => {
        client.get(url)
            .then(response => {
                resolve(response);
            })
            .catch(reject);
    });
};

export default {
    listCategories,
    getSubcategoriesByCategoryId
};

// https://saas-ecommerce-api-development.up.railway.app/api/products/get-by-category?categoryId=1&limit=10&page=1