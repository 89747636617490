"use client";

import {
  Popover,
  PopoverButton,
  PopoverPanel,
  Transition,
} from "@/app/headlessui";
import { ChevronDownIcon } from "@heroicons/react/24/solid";
import { MEGAMENU_TEMPLATES } from "@/data/navigation";
import CardCategory3 from "@/components/CardCategories/CardCategory3";
import { NavItemType } from "@/shared/Navigation/NavigationItem";
import Link from "next/link";
import React, { useEffect, useState } from "react";
import { ICategory, ICategoryResponse, ISubcategory } from "@/interfaces/categories.interface";
import useCategoriesStore from "@/store/categories/categories.store";
import { useStore } from "zustand";
import { usePathname, useRouter, useSearchParams } from "next/navigation";

type TemplatesDropdownProps = {
  // showCategoriesDropdown: boolean;
  // setShowCategoriesDropdown: React.Dispatch<React.SetStateAction<boolean>>;
}

const TemplatesDropdown: React.FC<TemplatesDropdownProps> = ({
  // showCategoriesDropdown,
  // setShowCategoriesDropdown,
}) => {

  const [showCategoriesDropdown, setShowCategoriesDropdown] = useState(false);
  const [categories, setCategories] = useState<ICategoryResponse[] | null>(null); // Estado para almacenar datos del usuario
  const categoriesStore = useStore(useCategoriesStore, (state) => state);

  const pathname = usePathname();
  const searchParams = useSearchParams();
  const router = useRouter();

  useEffect(() => {
    const fetchData = async () => {
      try {
        await fetchUserData();
      } catch (error) {
        console.error('Error fetching user data:', error);
        // Manejar el error de manera adecuada (mostrar mensaje al usuario, etc.)
      }
    };

    fetchData();
  }, []);

  const fetchUserData = async () => {
    try {
      const response = await categoriesStore.listCategories();
      setCategories(response);
      // console.log('categories', categories);
    } catch (error) {
      console.error('Error fetching user data:', error);
      // Manejar el error de manera adecuada (mostrar mensaje al usuario, etc.)
    }
  };

  const renderMegaMenuNavlink = (item: ISubcategory) => {
    // console.log('renderMegaMenuNavlink', item);

    return (
      <li key={item.id}> {/*className={`${item.isNew ? "menuIsNew" : ""}`}*/}
        <Link
          className="font-normal text-slate-600 hover:text-black dark:text-slate-400 dark:hover:text-white"
          // href={'/categories?categoryId=22'}
          href={{
            pathname: '/categories',
            query: { categoryId: item.id }
          }}
        // onClick={() => goToCategory(item)}
        >
          {item.nombre}
        </Link>
      </li>
    );
  };

  const goToCategory = (item: ISubcategory) => {
    const current = new URLSearchParams(Array.from(searchParams.entries()));

    // actualizar según sea necesario
    // const value = event.target.value.trim();

    if (!item.id) {
      current.delete("categoryId");
    } else {
      current.set("categoryId", item.id.toString());
    }

    // convertir a cadena
    const search = current.toString();
    // construir el query
    const query = search ? `?${search}` : "";

    // redirigir con el nuevo query
    router.refresh();
    //@ts-ignore    
    router.replace(`categories/${query}`, { scroll: false })
  }

  return (
    <div className="TemplatesDropdown hidden lg:block">
      <Popover className="">
        {({ open, close }) => (
          <>
            <PopoverButton
              className={`
                ${open ? "" : "text-opacity-80"}
                group h-10 sm:h-12 px-3 py-1.5 inline-flex items-center text-sm text-gray-800 dark:text-slate-300 font-medium hover:text-opacity-100 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75`}
            >
              <span className="">Categorias</span>
              <ChevronDownIcon
                className={`${open ? "-rotate-180" : ""}
                  ml-1 h-4 w-4 transition ease-in-out duration-150 `}
                aria-hidden="true"
              />
            </PopoverButton>
            <Transition
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <PopoverPanel className="absolute z-20 w-full mt-3.5 inset-x-0">
                <div className="bg-white dark:bg-neutral-900 shadow-lg">
                  <div className="container">
                    <div className="flex text-sm border-t border-slate-200 dark:border-slate-700 py-14">
                      <div className="flex-1 grid grid-cols-4 gap-6 xl:gap-8 pr-6 xl:pr-8">
                        {categories! && categories!.map((item, index) => {
                          // console.log('categories', item.category);

                          return (
                            <div key={index}>
                              <p className="font-medium text-slate-900 dark:text-neutral-200">
                                {
                                  item.category.nombre
                                }
                              </p>
                              <ul className="grid space-y-4 mt-4"
                                onClick={() => {
                                  console.log('closed');
                                  close();
                                  // setShowCategoriesDropdown(false)
                                }}
                              >
                                {item.category.subcategorias?.slice(0, 5).map(renderMegaMenuNavlink)}
                              </ul>
                            </div>
                          )
                        })}
                      </div>
                      <div className="w-[40%] xl:w-[35%]">
                        <CardCategory3 />
                      </div>
                    </div>
                  </div>
                </div>
              </PopoverPanel>
            </Transition>
          </>
        )}
      </Popover>
    </div>
  );
}

export default TemplatesDropdown;
