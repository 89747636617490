import img1 from "@/images/collections/1.png";
import img2 from "@/images/collections/5.png";
import img3 from "@/images/collections/4.png";
import img4 from "@/images/collections/3.png";
import { CardCategory3Props } from "./CardCategory3";

export const CATS_DISCOVER: CardCategory3Props[] = [
  {
    "name": "Explorar las novedades",
    "desc": "Compra lo último <br /> de las mejores marcas",
    "featuredImage": "https://ftp3.syscom.mx/usuarios/fotos/BancoFotografiasSyscom/LENOVO/THINKPADT14/THINKPADT14-p.PNG",
    "redirectUri": 'categories?productId=226212&categoryId=66663',
    "color": "bg-yellow-50"
  },
  {
    "name": "Lo último en tecnología",
    "desc": "Compra las innovaciones <br /> de marcas reconocidas",
    "featuredImage": "https://ftp3.syscom.mx/usuarios/fotos/BancoFotografiasSyscom/HILOOK/THCB120M/THCB120M-p.PNG",
    "redirectUri": 'categories?categoryId=214',
    "color": "bg-red-50"
  },
  {
    "name": "Últimos lanzamientos",
    "desc": "Compra las últimas <br /> novedades de <br /> marcas líderes",
    "featuredImage": "https://ftp3.syscom.mx/usuarios/fotos/BancoFotografiasSyscom/DJI/AGRAST30/AGRAST30-p.PNG",
    "redirectUri": 'categories?categoryId=66369',
    "color": "bg-green-50"
  },
  {
    "name": "Novedades imperdibles",
    "desc": "Adquiere los productos <br /> de las mejores marcas",
    "featuredImage": "https://ftp3.syscom.mx/usuarios/fotos/BancoFotografiasSyscom/EPCOMPOWERLINE/EPU600L/EPU600L-p.PNG",
    "redirectUri": 'categories?categoryId=528',
    "color": "bg-blue-50"
  },
];
