import client from './client';

import { setCookie } from 'cookies-next';

const ENDPOINT = "auth";

interface LoginData {
    email: string;
    password: string;
}

interface RegisterData {
    name: string;
    lastname: string;
    phone: string;
    email: string;
    password: string;
    role: string;
    streetOne: string;
    colony?: string;
    delegation?: string;
    state?: string;
    postalCode?: string;
    addressType?: string;
}

interface VeifyAccountData {
    accessToken: string;
}

interface SocialLoginData {
    token: string;
}

interface RecoveryPasswordData {
    email: string;
}

interface ResetPasswordData {
    userId: string;
    password: string;
    passwordConfirmation: string;
}

interface GetUserData {
    userId: string;
}

const login = (data: LoginData): Promise<any> => {
    const url = `${client.BASE_URL}/api/${ENDPOINT}/login`;
    return new Promise((resolve, reject) => {
        client.post(url, data)
            .then(response => {
                resolve(response);
            })
            .catch(reject);
    });
};

const register = (data: RegisterData): Promise<any> => {
    const url = `${client.BASE_URL}/api/${ENDPOINT}/register`;

    return new Promise((resolve, reject) => {
        client.post(url, data)
            .then(response => resolve(response))
            .catch(reject);
    });
};


const verify_profile = (code: string): Promise<any> => {
    const url = `${client.BASE_URL}/api/${ENDPOINT}/verify/${code}`;

    return new Promise((resolve, reject) => {
        client.get(url)
            .then(response => resolve(response))
            .catch(reject);
    });
};

const forgotPassword = (data: RecoveryPasswordData): Promise<any> => {
    const url = `${client.BASE_URL}/api/${ENDPOINT}/forgot-password`;

    return new Promise((resolve, reject) => {
        client.post(url, data)
            .then(response => resolve(response))
            .catch(reject);
    });
};

const resetPassword = (data: ResetPasswordData): Promise<any> => {
    const url = `${client.BASE_URL}/api/${ENDPOINT}/reset-password`;

    console.log('data', data);

    return new Promise((resolve, reject) => {
        client.post(url, data)
            .then(response => resolve(response))
            .catch(reject);
    });
};

const verifyAccount = (data: VeifyAccountData): Promise<any> => {
    const url = `${client.BASE_URL}/api/${ENDPOINT}/activate-account`;


    return new Promise((resolve, reject) => {
        client.patch(url, data)
            .then(response => resolve(response))
            .catch(reject);
    });
};


export default {
    login,
    register,
    verifyAccount,
    // verify_profile,
    forgotPassword,
    resetPassword
};