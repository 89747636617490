import client from './client';

import { setCookie } from 'cookies-next';

const ENDPOINT = "users";

interface GetUserData {
    userId: string;
}

interface UploadProfilePictureData {
    imageBase64: string | ArrayBuffer,
    // folder: string,
    userId: string | undefined,
}

interface UpdateUserData {
    name: string;
    lastname: string;
    phone: string;
    email: string;
    uuid: string;
}


const getUser = (data: GetUserData): Promise<any> => {
    const url = `${client.BASE_URL}/api/client/${ENDPOINT}?userId=${data.userId}`;

    console.log('data', data);

    return new Promise((resolve, reject) => {
        client.get(url)
            .then(response => resolve(response.data))
            .catch(reject);
    });
};


const updateProfilePicture = (data: UploadProfilePictureData): Promise<any> => {
    const url = `${client.BASE_URL}/api/client/${ENDPOINT}/upload-profile-picture`;

    return new Promise((resolve, reject) => {
        client.post(url, data)
            .then(response => resolve(response.data))
            .catch(reject);
    });
};

const updateUser = (data: UpdateUserData): Promise<any> => {
    const url = `${client.BASE_URL}/api/client/${ENDPOINT}/update-user`;

    return new Promise((resolve, reject) => {
        client.post(url, data)
            .then(response => resolve(response.data))
            .catch(reject);
    });
};

export default {
    getUser,
    updateProfilePicture,
    updateUser
};