import { IUser } from '@/interfaces/user.interface';
import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import { deleteCookie, setCookie } from 'cookies-next';
import auth from '@/api/auth';
import { jwtDecode } from 'jwt-decode';
import user from '@/api/user';


interface AuthState {
    isAuthenticated: boolean;
    user: null | IUser;
    token: null | string;
    login: (email: string, password: string) => Promise<any>;
    register: (
        name: string,
        lastname: string,
        phone: string,
        email: string,
        password: string,
        role: string,
        streetOne: string,
        colony?: string,
        delegation?: string,
        state?: string,
        postalCode?: string,
        addressType?: string
    ) => Promise<any>;
    forgotPassword: (email: string) => Promise<{ message: boolean }>;
    resetPassword: (
        accessToken: string | null,
        password: string,
        passwordConfirmation: string
    ) => Promise<any>;
    getUser: (userId: string) => Promise<any>;
    // saveToken(accessToken: string): void;
    // getUser(accessToken: string): Promise<string>;
    verifyAccount: (accessToken: string) => Promise<any>;
    updatePictureProfile: (uuid: string | undefined, image: string | ArrayBuffer) => Promise<any>;
    updateUser: (
        uuid: string | undefined,
        name: string,
        lastname: string,
        phone: string,
        email: string,
    ) => Promise<any>;
    logout: () => void;
}

const useAuthStore = create<AuthState>()(
    persist(
        (set) => ({
            isAuthenticated: false,
            user: null,
            token: null,
            login: async (email, password) => {
                // Login user code
                const response = await auth.login({ email, password });

                console.log('datadata', response);

                if (response.data) {
                    setCookie('token', response.data?.accessToken);
                    setCookie('user', response.data?.user);
                    // window.sessionStorage.setItem('user', JSON.stringify(response.data?.user));
                    set((state) => ({
                        ...state,
                        isAuthenticated: true,
                        user: response.data?.user,
                        token: response.data?.accessToken,
                    }));
                    return response.data;
                }

                return null;
            },
            register: async (
                name: string,
                lastname: string,
                phone: string,
                email: string,
                password: string,
                role: string,
                streetOne: string,
                colony?: string,
                delegation?: string,
                state?: string,
                postalCode?: string,
                addressType?: string,
            ) => {
                // Registering user code

                const { data } = await auth.register({
                    name: name,
                    lastname: lastname,
                    phone: phone,
                    email: email,
                    password: password,
                    role: role,
                    streetOne: streetOne,
                    colony: colony,
                    delegation: delegation,
                    state: state,
                    postalCode: postalCode,
                    addressType: addressType,
                });

                console.log('data', data);

                if (data) {
                    //     setCookie('token', data?.data.register?.accessToken);
                    //     set((state) => ({
                    //         ...state,
                    //         isAuthenticated: true,
                    //         user: data?.data.register?.user,
                    //         token: data?.data.register?.accessToken,
                    //     }));
                    return data;
                }

                return null;
            },
            forgotPassword: async (email: string) => {
                const response = await auth.forgotPassword({ email });

                console.log('response', response);

                return response.data;

            },

            resetPassword: async (
                accessToken: string | null,
                password: string,
                passwordConfirmation: string
            ) => {
                setCookie('token', accessToken);

                const decodeToken: { id: string } = jwtDecode(accessToken!);

                console.log('decodeToken', decodeToken);

                console.log('passwordConfirmation', passwordConfirmation);

                const response = await auth.resetPassword({
                    userId: decodeToken.id,
                    password: password,
                    passwordConfirmation: passwordConfirmation,
                });

                console.log('response', response);

                deleteCookie('token');

                return response.data;
            },

            getUser: (userId: string) => {
                // get user
                const response = user.getUser({
                    userId: userId
                });

                return response;
            },
            // saveToken: (accessToken: string) => {
            //     // get user
            //     set((state) => ({
            //         ...state,
            //         isAuthenticated: true,
            //         token: accessToken,
            //     }));
            // },
            updatePictureProfile: async (uuid: string | undefined, image: string | ArrayBuffer): Promise<any> => {
                const response = user.updateProfilePicture({
                    imageBase64: image,
                    userId: uuid!,
                });

                return response;
            },
            updateUser: async (
                uuid: string | undefined,
                name: string,
                lastname: string,
                phone: string,
                email: string,
            ): Promise<any> => {
                const response = user.updateUser(
                    {
                        name: name,
                        lastname: lastname,
                        phone: phone,
                        email: email,
                        uuid: uuid!
                    });

                return response;
            },
            verifyAccount: (accessToken: string) => {
                // verify account
                const response = auth.verifyAccount({
                    accessToken: accessToken
                })

                return response;
            },
            logout: () => {
                // Logout user code
            }
        }),
        {
            name: 'auth',
        }
    )
);

export default useAuthStore;